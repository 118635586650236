<template>
  <div class="search">
    <Card>
      <Row v-show="openSearch" @keydown.enter.native="handleSearch">
        <Collapse value="1" accordion style="width:100%">
          <Panel name="1">查询条件
            <p slot="content">
              <Form ref="searchForm" :model="searchForm" :label-width="80" label-position="right">
                <Row :gutter="32">
                  <Col span="7">
                  <Form-item label="标题" prop="wakeTitle">
                    <Input type="text" v-model="searchForm.wakeTitle" placeholder="请输入标题" clearable />
                  </Form-item>
                  </Col>
                  <Col span="7">
                  <Form-item label="提醒类型" prop="wakeType">
                    <Select v-model="searchForm.wakeType" clearable>
                      <Option v-for="(item, i) in this.$store.state.dict.wake_type" :key="i" :value="item.value">
                        {{ item.title }}
                      </Option>
                    </Select>
                  </Form-item>
                  </Col>
                  <Col span="7">
                  <Form-item label="结束标志" prop="isEnd">
                    <Select v-model="searchForm.isEnd" placeholder="请选择" clearable>
                      <Option v-for="(item, i) in listIsEnd" :key="i" :value="item.value">{{ item.label }}</Option>
                    </Select>
                  </Form-item>
                  </Col>
                </Row>
              </Form>
            </p>
          </Panel>
        </Collapse>
      </Row>
      <Row @keydown.enter.native="handleSearch">
        <Form>
          <Form-item style="margin-top: 10px" class="br">
            <Button @click="handleSearch" type="primary" icon="ios-search">搜索</Button>
            <Button @click="add" type="warning" icon="md-add">添加</Button>
          </Form-item>
        </Form>
      </Row>
      <Row>
        <Table :loading="loading" border :columns="columns" :data="data" ref="table" sortable="custom"
          @on-sort-change="changeSort" @on-selection-change="changeSelect"></Table>
      </Row>
      <Row type="flex" justify="end" class="page">
        <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage"
          @on-page-size-change="changePageSize" :page-size-opts="[10, 20, 50]" size="small" show-total show-elevator
          show-sizer></Page>
      </Row>
    </Card>
    <wakeAddEdit :data="formData" :addEditFlag="addEditFlag" v-model="showWakeAddEdit" @on-submit="getDataList" />
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    getWakeList,
    deleteWake,
    deleteWakeHis,
  } from "@/api/busi/wake-manage/wake";
  // 根据你的实际添加编辑组件位置路径修改
  import wakeAddEdit from "./wakeAddEdit.vue";
  export default {
    name: "wake",
    components: {
      wakeAddEdit,
    },
    data() {
      return {
        openSearch: true, // 显示搜索
        formData: {},
        addEditFlag: "1",
        loading: true, // 表单加载状态
        showWakeAddEdit: false,
        searchForm: {
          // 搜索框初始化对象
          pageNumber: 1, // 当前页数
          pageSize: 10, // 页面大小
          sort: "createTime", // 默认排序字段
          order: "desc", // 默认排序方式
          wakeTitle: "", // 标题
          wakeType:"", // 提醒类型
          isEnd: -1, // 提醒状态
        },
        selectDate: null,
        listIsEnd: [{
            value: "-1",
            label: "全部",
          },
          {
            value: "0",
            label: "未结束",
          },
          {
            value: "1",
            label: "已结束",
          },
        ],
        columns: [
          // 表头
          {
            type: "index",
            width: 60,
            fixed: "left",
            align: "center",
          },
          {
            title: "标题",
            key: "wakeTitle",
            minWidth: 150,
            sortable: true,
            align: "center",
            render: (h, params) => {
              return h(
                "div", {
                  style: {
                    textAlign: "left",
                  },
                },
                [
                  h(
                    "span", {
                      style: {},
                    },
                    params.row.wakeTitle
                  ),
                ]
              );
            },
          },
          {
            title: "提醒内容",
            key: "wakeDesc",
            minWidth: 250,
            sortable: false,
            align: "center",
            render: (h, params) => {
              return h(
                "div", {
                  style: {
                    textAlign: "left",
                  },
                },
                [
                  h(
                    "span", {
                      style: {},
                    },
                    params.row.wakeDesc
                  ),
                ]
              );
            },
          },
          {
            title: "提醒类型",
            key: "wakeType",
            minWidth: 120,
            align: "center",
            sortable: false,
            render: (h, params) => {
              let re = "";
              if (params.row.wakeType == 0) {
                re = "单次提醒";
              } else if (params.row.wakeType == 1) {
                re = "每天提醒";
              } else if (params.row.wakeType == 2) {
                re = "每周提醒";
              } else if (params.row.wakeType == 3) {
                re = "每月提醒";
              }
              return h("div", re);
            },
          },
          {
            title: "提醒周期",
            key: "wakeTime1",
            minWidth: 120,
            sortable: true,
            align: "center",
            render: (h, params) => {
              let re = "";
              if (params.row.wakeType == 0 || params.row.wakeType == 1) {
                re = "--";
              } else if (params.row.wakeType == 2) {
                if (params.row.wakeTime1 == "2") {
                  re = "周一";
                } else if (params.row.wakeTime1 == "3") {
                  re = "周二";
                } else if (params.row.wakeTime1 == "4") {
                  re = "周三";
                } else if (params.row.wakeTime1 == "5") {
                  re = "周四";
                } else if (params.row.wakeTime1 == "6") {
                  re = "周五";
                } else if (params.row.wakeTime1 == "7") {
                  re = "周六";
                } else if (params.row.wakeTime1 == "1") {
                  re = "周日";
                }
              } else if (params.row.wakeType == 3) {
                if (params.row.wakeTime1 != "32") {
                  re = params.row.wakeTime1 + "号";
                } else {
                  re = "月末";
                }
              }
              return h("div", re);
            },
          },
          {
            title: "提醒时间",
            key: "wakeTime2",
            minWidth: 180,
            sortable: true,
            align: "center",
          },
          {
            title: "提醒结束时间",
            key: "endTime",
            minWidth: 180,
            sortable: true,
            align: "center",
            render: (h, params) => {
              let re = "";
              if (params.row.wakeType == 0) {
                re = "--";
              } else {
                re = params.row.endTime;
              }
              return h("div", re);
            },
          },
          {
            title: "提醒状态",
            key: "sendStatus",
            minWidth: 130,
            align: "center",
            sortable: false,
            render: (h, params) => {
              let re = "";
              if (params.row.sendStatus == 0) {
                re = "未提醒";
              }else if (params.row.sendStatus == 1) {
                re = "已成功提醒";
              }else if (params.row.sendStatus == -1) {
                re = "提醒失败，未绑定公众号";
              }else{
                re = "提醒失败，原因未知";
              }
              return h("div", re);
            },
          },
          {
            title: "已提醒时间",
            key: "sendTime",
            minWidth: 180,
            align: "center",
            sortable: false,
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h("div", [
                h(
                  "Button", {
                    props: {
                      type: "success",
                      size: "small",
                      icon: "ios-create-outline",
                      // hidden: this.fromFlag == "1",
                    },
                    style: {
                      marginRight: "5px",
                    },
                    on: {
                      click: () => {
                        this.edit(params.row);
                      },
                    },
                  },
                  (params.row.fromFlag=="1")?"编辑":"查看"
                ),
                h(
                  "Button", {
                    props: {
                      type: "warning",
                      size: "small",
                      icon: "md-trash",
                    },
                    on: {
                      click: () => {
                        this.remove(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
            },
          },
        ],
        data: [], // 表单数据
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        total: 0, // 表单数据总数
      };
    },
    methods: {
      init() {
        this.getDataList();
      },
      submited() {
        this.getDataList();
      },
      changePage(v) {
        this.searchForm.pageNumber = v;
        this.getDataList();
        this.clearSelectAll();
      },
      changePageSize(v) {
        this.searchForm.pageSize = v;
        this.getDataList();
      },
      handleSearch() {
        this.searchForm.pageNumber = 1;
        this.searchForm.pageSize = 10;
        this.getDataList();
      },
      handleReset() {
        this.$refs.searchForm.resetFields();
        this.searchForm.pageNumber = 1;
        this.searchForm.pageSize = 10;
        this.selectDate = null;
        this.searchForm.startDate = "";
        this.searchForm.endDate = "";
        // 重新加载数据
        this.getDataList();
      },
      changeSort(e) {
        this.searchForm.sort = e.key;
        this.searchForm.order = e.order;
        if (e.order === "normal") {
          this.searchForm.order = "";
        }
        this.getDataList();
      },
      getDataList() {
        if (this.searchForm.wakeType == undefined) this.searchForm.wakeType="";
        if (this.searchForm.isEnd == undefined) this.searchForm.isEnd=-1;
        this.loading = true;
        getWakeList(this.searchForm).then((res) => {
          this.loading = false;
          if (res.success) {
            this.data = res.result.records;
            this.total = res.result.total;
          }
        });
      },
      add() {
        this.addEditFlag = "1";
        this.showWakeAddEdit = true;
      },
      edit(v) {
        // 转换null为""
        for (let attr in v) {
          if (v[attr] == null) {
            v[attr] = "";
          }
        }
        let str = JSON.stringify(v);
        let data = JSON.parse(str);
        this.addEditFlag = "2";
        this.formData = data;
        this.showWakeAddEdit = true;
      },
      remove(v) {
        this.$Modal.confirm({
          title: "确认删除",
          // 记得确认修改此处
          content: "您确认要删除 " + v.wakeTitle + " ?",
          loading: true,
          onOk: () => {
            if (v.fromFlag == "1") {
              // 删除当前表
              deleteWake({
                ids: v.id,
              }).then((res) => {
                this.$Modal.remove();
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.getDataList();
                }
              });
            } else{
             // 删除历史表
              deleteWakeHis({
                ids: v.id,
              }).then((res) => {
                this.$Modal.remove();
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.getDataList();
                }
              });
            }  
          },
        });
      },
    },
    mounted() {
      this.init();
    },
  };
</script>
<style lang="less">
  // 建议引入通用样式 具体路径自行修改 可删除下面样式代码
  // @import "../../../styles/table-common.less";
  .search {
    .operation {
      margin-bottom: 2vh;
    }

    .select-count {
      font-weight: 600;
      color: #40a9ff;
    }

    .select-clear {
      margin-left: 10px;
    }

    .page {
      margin-top: 2vh;
    }

    .drop-down {
      margin-left: 5px;
    }
  }
</style>